import {Component, NgModule, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {Message, PasswordModule} from 'primeng/primeng';

import {DtoServicios} from '../../../../util/servicios/dto.servicios';
import {AppService} from '../../../../util/servicios/app.service';
import {ImpresionService} from '../../../servicios/impresion.service';
import {BaseComponent} from '../../../shared/componentes/base.component';
import { LocalStorageService } from '../../sesion/localStorageService';


@Component({
  selector: 'app-olvido-contrasenia',
  templateUrl: './olvidoContrasenia.component.html'
})
export class OlvidoContraseniaComponent extends BaseComponent implements OnInit {

  /**Control de ejecucion del otp, previene que se ejecute varias veces el boton del otp. */
  private enejecucion = false;

  public identificacion = null;
  /**Objeto que contine mensajes aplicativos. */
  msgs: Message[] = [];

  constructor(public localStorageService:LocalStorageService, public router: Router, public dtoServicios: DtoServicios, public appService: AppService, public impresionService: ImpresionService) {
    super(localStorageService, router, dtoServicios, 'Abstract', 'LOGIN', false, false);
    this.componentehijo = this;
  }

  ngOnInit() {

  }

  /**Invoca al core para realizar la validaicon del olvido de contrasenia. */
  ejecutaValidacionOlvContr() {
    this.msgs = [];
    if (this.enejecucion) {
      return;
    }
    this.encerarMensajes();
    this.appService.login = true;
    this.appService.validarotp = true;
    const rq = new Object();
    rq['ident'] = this.identificacion;
    this.dtoServicios.ejecutarServicioBancaLinea(rq, 23, 5)
      .subscribe(
      resp => {
        this.manejaRespuesta(resp);
        this.enejecucion = false;
      },
      error => {
        this.enejecucion = false;
        this.dtoServicios.manejoError(error);
      });
  }

  /**Manejo respuesta de ejecucion de login. */
  private manejaRespuesta(resp: any) {  
    this.msgs = [];
    if (resp.cod === 'OK') {
      this.identificacion = null;
      this.appService.modelo = {};
      if (resp.validarclavetemp !== null && resp.validarclavetemp !== undefined) {
        this.msgs.push({severity: 'success', summary: 'TRANSACCIÓN CORRECTA '});
        this.msgs.push({severity: 'success', summary: 'REVISAR SU EMAIL CON SU CONTRASEÑA TEMPORAL'});
        this.dtoServicios.mostrarMensaje(this.msgs);
        
        this.appService.login = false;
        this.appService.validarclavetemp = false;
        this.appService.validarotp = false;
      } else {
        this.appService.validarclavetemp = false;
      }
    }
    if (resp.cod !== 'OK') {
      let msg = '';
      //msg = resp.cod !== undefined ? msg = msg + resp.cod + ' ' : msg + ' ';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      if (resp.cod === 'SEG-001') {
        this.msgs.push({severity: 'error', summary: 'NO EXISTE USUARIO CON CÉDULA:  ' + this.identificacion});
      }
      else {
        this.msgs.push({severity: 'error', summary: msg});
      }
      this.dtoServicios.mostrarMensaje(this.msgs);
      // this.dtoServicios.llenarMensaje(resp, false);
    }
  }

  public cancelar() {
    this.dtoServicios.encerarCredencialesLogin();
    location.reload();
  }

}
