﻿import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { InicioComponent } from "../../sobre/inicio/componentes/inicio.component";
import { LoginComponent } from "../seguridad/componentes/login/componentes/login.component";
import { ReloadComponent } from "../seguridad/componentes/login/componentes/reload.component";

import { VerificaAutorizacionService } from "../../util/servicios/verificaAutorizacion.service";

const appRoutes: Routes = [
  {
    path: "",
    component: InicioComponent,
    canActivate: [VerificaAutorizacionService]
  },
  { path: "reload", component: ReloadComponent },
  { path: "**", redirectTo: "" },
  { path: "23-6", loadChildren: "app/modulos/seguridad/cambiocontrasenia/cambioContrasenia.module#CambioContraseniaModule", canActivate: [VerificaAutorizacionService] },
  { path: "23-100", loadChildren: "app/modulos/socios/informacionGeneral/informacionGeneral.module#InformacionGeneralModule", canActivate: [VerificaAutorizacionService] },

  { path: "23-101", loadChildren: "app/modulos/socios/consultaAportes/consultaAportes.module#consultaAportesModule", canActivate: [VerificaAutorizacionService] },

  { path: "23-200", loadChildren: "app/modulos/cartera/simulacionCartera/simulacionCartera.module#SimulacionCarteraModule", canActivate: [VerificaAutorizacionService] },

  { path: "23-201", loadChildren: "app/modulos/cartera/consultaOperacion/consultaOperacion.module#ConsultaOperacionModule", canActivate: [VerificaAutorizacionService] },
  { path: "23-202", loadChildren: "app/modulos/cartera/listadoOperaciones/listadoOperaciones.module#ListadoOperacionesModule", canActivate: [VerificaAutorizacionService] },

  { path: "23-203", loadChildren: "app/modulos/cartera/solicitudes/solicitudes.module#SolicitudesModule", canActivate: [VerificaAutorizacionService] },

  { path: "23-204", loadChildren: "app/modulos/web/inicio/inicio.module#InicioModule", canActivate: [VerificaAutorizacionService] },
  
  { path: "23-200", loadChildren: "app/modulos/cartera/solicitudcapacidadpago/solicitudcapacidadpago.module#SolicitudCapacidadPagoModule", canActivate: [VerificaAutorizacionService] },

  { path: "23-210", loadChildren: "app/modulos/cartera/certificadoCartera/certificadoCartera.module#CertificadoCarteraModule", canActivate: [VerificaAutorizacionService] },
  { path: "23-300", loadChildren: "app/modulos/prestaciones/simulacionPrestaciones/simulacionPrestaciones.module#SimulacionPrestacionesModule", canActivate: [VerificaAutorizacionService] },
  { path: "23-301", loadChildren: "app/modulos/socios/consultaexpediente/consultaExpediente.module#ConsultaExpedienteModule", canActivate: [VerificaAutorizacionService] },
  { path: "**", redirectTo: "" }
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
