import {Component, NgModule, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {Message, PasswordModule} from 'primeng/primeng';

import {DtoServicios} from '../../../../servicios/dto.servicios';
import {AppService} from '../../../../servicios/app.service';
import {SubscripcionComponent} from '../../subscripcion/componentes/subscripcion.component';
import {BaseComponent} from '../../../../shared/componentes/base.component';
import { LocalStorageService } from 'app/util/seguridad/sesion/localStorageService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {
  
  @ViewChild(SubscripcionComponent)
  subscripcion: SubscripcionComponent;

  /**Control de ejecucion del login, previene que se ejecute varias veces el boton del login. */
  private enejecucion = false;
  /**Objeto que contine mensajes aplicativos. */
  msgs: Message[] = [];

  constructor(public localStorageService: LocalStorageService, router: Router, dtoServicios: DtoServicios, public appService: AppService) {
    super(localStorageService, router, dtoServicios, 'Abstract', 'LOGIN', false, false);
    this.componentehijo = this;
  }
  
  ngOnInit() {
    // consulta la ip local
    this.dtoServicios.consultarIpLocal();
  }

  /**Invoca al core para realizar login de la aplicacion. */
  ejecutalogin() {
    this.msgs = [];
    if (this.enejecucion) {
      return;
    }
    this.enejecucion = true;
    this.appService.login = false;
    this.dtoServicios.ejecutarLogin(this.appService.modelo.u, this.appService.modelo.p, 'BAN', '1')
      .subscribe(
      resp => {
        this.manejaRespuestaLogin(resp);
        this.enejecucion = false;
      },
      error => {
        this.enejecucion = false;
        this.dtoServicios.manejoError(error);
      });
  }

  /**Manejo respuesta de ejecucion de login. */
  private manejaRespuestaLogin(resp: any) {
    this.msgs = [];
    if (resp.cod === 'OK') {
      this.appService.modelo = {};
      if (resp.validarotp !== null && resp.validarotp !== undefined) {
        this.appService.validarotp = resp.validarotp;
        this.localStorageService.setItem('validarotp', resp.validarotp + '');
      } else {
        this.appService.validarotp = false;
        this.localStorageService.setItem('validarotp', 'false');
      }
      this.inicializarAmbiente(resp); // manejo de respuesta
    }
    if (resp.cod !== 'OK') {
      let msg = '';
      msg = resp.cod !== undefined ? msg = msg + resp.cod + ' ' : msg + ' ';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      this.dtoServicios.llenarMensaje(resp, false);
    }
  }
  
  /**Invoca al core para realizar el olvido de contrasenia de la aplicacion. */
  ejecutaolvidocontrasenia() {
    this.encerarMensajes();
    this.msgs = [];
    if (this.enejecucion) {
      return;
    }
    this.enejecucion = false;
    this.appService.login = true;
    this.appService.validarclavetemp = true;
    this.appService.validarotp = true;
  }
  
  public iniciaRegistroUsuario(){
    this.encerarMensajes();
    this.subscripcion.registrousuario = true;
    this.subscripcion.crearNuevo();
    this.subscripcion.regpaso0 = true;
  }

  /**Fija datos de respuesta cuando el login es exitoso. */
  private inicializarAmbiente(resp: any) {
    const mradicacion = resp.mradicacion;
    //  cia^suc^ofi^usr^perfil^idioma^canal^ipaddress
    let c: string;
    const ip = document.domain === 'localhost' ? '127.0.0.1' : document.domain;
    //  Lista de roles del usuario.
    this.appService.llenarRolesUsuario(mradicacion.roles);

    c = mradicacion.cc + '^' + mradicacion.cs + '^' + mradicacion.cag + '^' + mradicacion.cu + '^';
    c = c + mradicacion.roles[0].id + '^' + mradicacion.ci + '^' + mradicacion.cca;
    this.localStorageService.setItem('c', c);
    this.localStorageService.setItem('mradicacion', JSON.stringify(mradicacion));
    // delete mradicacion.roles;

    // ejecuta consulta del menu del primer rol
    this.appService.consultarMenu();

    // Fija datos de radicacion del usuario en el singleton de servicios.
    this.dtoServicios.actualizarRadicacion(mradicacion);

    // Nombre de la persona
    this.appService.npersona = mradicacion.np;

    // Verifica el cambio de password
    this.validaCambioPassword(mradicacion);

    if (!this.appService.validarotp && !this.appService.cambiopassword) {
      this.msgs.push({severity: 'success', summary: 'INGRESO EXITOSO, BIENVENIDO: ' + this.dtoServicios.mradicacion.cusuario, detail: ''});
      this.dtoServicios.mostrarMensaje(this.msgs);
    } else {
      this.msgs.push({severity: 'success', summary: 'LOGIN EXITOSO, VALIDACIÓN DOBLE FACTOR DE AUTENTICACIÓN (2FA)', detail: ''});
      this.dtoServicios.mostrarMensaje(this.msgs);
    }
  }

  validaCambioPassword(mradicacion: any) {
    const cambio = mradicacion.pss;
    if (cambio !== null && cambio === '1') {
      this.appService.cambiopassword = true;
      this.appService.cambiocontraseniacomponent.consultar();
    }
  }


}
