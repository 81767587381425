import { NgModule } from '@angular/core';

import { BasicModule } from './basic.module';

import { AccionesDialogoComponent } from './componentes/accionesDialogo.component';
import { AccionesConsultaComponent } from './componentes/accionesConsulta.component';
import { AccionesCabeceraComponent } from './componentes/accionesCabecera.component';
import { AccionesEditarComponent } from './componentes/accionesEditar.componente';
import { AccionesRegistroComponent } from './componentes/accionesRegistro.component';
import { AccionesReporteComponent } from './componentes/accionesReporte.component';
import { TooltipModule} from 'primeng/primeng';

@NgModule({
  imports: [BasicModule,TooltipModule],
  declarations: [AccionesDialogoComponent, AccionesConsultaComponent, AccionesCabeceraComponent, AccionesEditarComponent, AccionesRegistroComponent,
  AccionesReporteComponent],
  exports: [BasicModule, AccionesDialogoComponent,TooltipModule, AccionesConsultaComponent, AccionesCabeceraComponent, AccionesEditarComponent, AccionesRegistroComponent,
  AccionesReporteComponent]
})
export class SharedModule { }
