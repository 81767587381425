// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  secretLocalStorage:'%$"·$%//%····/_',
  fondo: 'SERVICIO INTEGRADO DE ADMINISTRACIÓN DE CESANTÍAS',
  terminos:"https://fondomunicipiodequito.com/wp-content/uploads/2023/06/Terminos-y-Condiciones.html",
  imagen: 'assets/layout/images/logotransparente.png',
  hmac: {
    secretHeader: 'hmacauth',
    APPId:'65d3a4f0-0239-404c-8394-21b94ff50604',
    APIKey: 'WLUEWeL3so2hdHhHM5ZYnvzsOUBzSGH4+T3EgrQ91KI=',
  },
  nsueldos:3
};
