import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../util/shared/shared.module';
import {SubscripcionRoutingModule} from './subscripcion.routing';
import { SubscripcionComponent } from './componentes/subscripcion.component';

@NgModule({
  imports: [SharedModule, SubscripcionRoutingModule],
  declarations: [SubscripcionComponent],
  exports: [SubscripcionComponent]
})
export class SubscripcionModule {}
