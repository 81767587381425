import {Injectable} from '@angular/core';
import {Routes, Router, ActivatedRoute, RouterModule, provideRoutes} from '@angular/router';
import {DtoServicios} from './dto.servicios';
import {SelectItem} from 'primeng/primeng';
import {Message} from 'primeng/primeng';
import {MenuItem} from 'primeng/primeng';
import {VerificaAutorizacionService} from '../../util/servicios/verificaAutorizacion.service';
import {InicioComponent} from '../../sobre/inicio/componentes/inicio.component';
import {ReloadComponent} from '../seguridad/componentes/login/componentes/reload.component';
import {CambioContraseniaLoginComponent} from '../../util/seguridad/componentes/cambiocontrasenialogin/componentes/cambioContraseniaLogin.component';
import { LocalStorageService } from '../seguridad/sesion/localStorageService';


@Injectable()
export class AppService {
  public modelo: any = {};
  /**Objeto que contine mensajes aplicativos. */
  msgs: Message[] = [];
  /**Menu asociado a un rol del usuario.*/
  lmenu: any = [];
  /**false, indica que el usuario no ingresa a la aplicacion, true el usuario esta en la aplicacion y se muestra el menu.*/
  public login = false;

  /**Varible que valida la la clave del otp*/
  public validarotp = true;
  /**Variable que valida el olvido contrasenia*/
  public validarclavetemp = false;
  /**Varible que valida la la clave del otp*/
  public cambiopassword = false;

  /**Varible de referencia al componente de cambio de password*/
  public cambiocontraseniacomponent: CambioContraseniaLoginComponent;

  /**Lista de roles del usuario.*/
  public lrolesusuario: SelectItem[] = [{label: '...', value: null}];
  /**Codigo de rol activo*/
  public crol: any;
   /**Nombre de rol activo*/
  public nrol: any;
  /**Nombre de rol activo*/
  public npersona: any;
  /** Menu asociado al rol */
  menu: MenuItem[];

  muestraRoles = false;

  muestraUserInfo = false;

  public titulopagina = '';

  /**Rutas por defecto**/
  public appRutasDefecto: Routes = [
    {path: '23-6', loadChildren: 'app/modulos/seguridad/cambiocontrasenia/cambioContrasenia.module#CambioContraseniaModule', canActivate: [VerificaAutorizacionService] },
    {path: '', component: InicioComponent, canActivate: [VerificaAutorizacionService]},
    {path: 'reload', component: ReloadComponent},
    {path: '**', redirectTo: ''},
  ];


  constructor(public router: Router, public dtoServicios: DtoServicios, public localStorageService:LocalStorageService ) {}

  /**Metodo que se ejecuta en el bootstrap de la aplicacion, o al salir de la aplicacion. */
  public salir(event = null): void {
    this.msgs = [];
    this.modelo = {};
    if (event !== null && event !== undefined) {
      event.preventDefault();
    }
    this.dtoServicios.logout();
    this.login = false;
  }

  /**Metodo que se ejecuta en el bootstrap de la aplicacion, o dar la ruta hacia cambio de password. */
  public cambioPassword(event = null): void {
    this.msgs = [];

    if (event !== null && event !== undefined) {
      event.preventDefault();
    }

    this.localStorageService.setItem('m', '23');
    this.localStorageService.setItem('t', '6');
    this.localStorageService.setItem('titulo', '23-6 Cambio De Constraseña');
    this.localStorageService.setItem('ins', 'true');
    this.localStorageService.setItem('upd', 'true');
    this.localStorageService.setItem('del', 'false');
    this.localStorageService.setItem('ac', 'false');
    this.localStorageService.setItem('path', '23-6');

    this.router.navigate(['23-6'], {skipLocationChange: true});
    this.titulopagina = '23-6 Cambio De Constraseña';
  }
  public paginaInicio(event = null): void {
    this.msgs = [];

    if (event !== null && event !== undefined) {
      event.preventDefault();
    }

    this.localStorageService.setItem('m', '23');
    this.localStorageService.setItem('t', '204');
    this.localStorageService.setItem('titulo', ' Inicio');
    this.localStorageService.setItem('ins', 'true');
    this.localStorageService.setItem('upd', 'true');
    this.localStorageService.setItem('del', 'false');
    this.localStorageService.setItem('ac', 'false');
    this.localStorageService.setItem('path', '23-204');

    this.router.navigate(['23-204'], {skipLocationChange: true});
    this.titulopagina = '23-204 Inicio';
  }

  // Lista de datos roles de usuario a utlizar en la pagina.
  public llenarRolesUsuario(lroles: any): void {
    this.lrolesusuario = [];
    for (const i in lroles) {
      if (lroles.hasOwnProperty(i)) {
        const reg = lroles[i];
        this.lrolesusuario.push({label: reg.name, value: reg.id});
      }
    }
    if (this.lrolesusuario != null && this.lrolesusuario.length > 0) {
      this.crol = this.lrolesusuario[0].value;
      this.nrol = this.lrolesusuario[0].label;
    }
  }

  /**Invoca al core para realizar login de la aplicacion. */
  public consultarMenu() {
    this.msgs = [];
    const rqConsulta: any = new Object();
    rqConsulta.CODIGOCONSULTA = 'MENU';
    rqConsulta.crol = this.crol;
    rqConsulta.angular = 'Y'; // para que reorne el menu en formato json
    rqConsulta.cmodulo = '23';
    rqConsulta.ctransaccion = '2';
    this.dtoServicios.ejecutarConsultaRest(rqConsulta, '', 'menu')
      .subscribe(
      resp => {
        this.manejaRespuestaMenu(resp);
      },
      error => {
        this.dtoServicios.manejoError(error);
      });
  }

  /**Manejo respuesta de ejecucion de login. */
  private manejaRespuestaMenu(resp: any) {
    this.msgs = [];
    if (resp.cod === 'OK') {
      this.menu = resp.menu;
      this.login = true;

      for (const i in resp.rutas) {
        if (resp.rutas.hasOwnProperty(i)) {
          resp.rutas[i].canActivate[0] = VerificaAutorizacionService;
        }
      }
      for (const i in this.appRutasDefecto) {
        if (this.appRutasDefecto.hasOwnProperty(i)) {
          resp.rutas.push(this.appRutasDefecto[i]);
        }
      }
      while (this.router.config.length > 0) {
        this.router.config.pop();
      }
      this.router.resetConfig(resp.rutas);
      this.paginaInicio(null);
    }
    if (resp.cod !== 'OK') {
      let msg = '';
      msg = resp.cod !== undefined ? msg = msg + resp.cod + ' ' : msg + ' ';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      this.dtoServicios.llenarMensaje(resp, false);
    }
   
  }

  /** Regenera el objeto que tiene la radicacion del usuario. */
  public fijarRadicacion(mradicacion: any) {
    let c: string;
    c = mradicacion.cc + '^' + mradicacion.cs + '^' + mradicacion.cag + '^' + mradicacion.cu + '^';
    c = c + this.crol + '^' + mradicacion.ci + '^' + mradicacion.cca;
    this.localStorageService.setItem('c', c);
    this.localStorageService.setItem('mradicacion', JSON.stringify(mradicacion));
  }

  public cargarRol(item: any) {
    this.crol = item.value;
    this.nrol = item.label;
    const mradicacion = JSON.parse(this.localStorageService.getItem('mradicacion'));
    this.fijarRadicacion(mradicacion);
    this.consultarMenu();
    this.mostrarRoles();
  }

  public mostrarRoles() {
    this.muestraRoles = !this.muestraRoles;
  }
}
