import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LocalStorageService } from 'app/util/seguridad/sesion/localStorageService';

import { DtoServicios } from '../../../../servicios/dto.servicios';
import { Consulta } from '../../../../dto/dto.component';
import { Mantenimiento } from '../../../../dto/dto.component';
import { BaseComponent } from '../../../../shared/componentes/base.component';
import { AppService } from '../../../../servicios/app.service';
import { SelectItem } from 'primeng/primeng';
import { environment } from 'environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-subscripcion',
  templateUrl: 'subscripcion.html'
})
export class SubscripcionComponent extends BaseComponent implements OnInit, AfterViewInit {

  /**Variable que indica que el usuario esta en modo registro*/
  public registrousuario = false;

  /**Variable que indica que el usuario esta en el paso 0 de registro*/
  public regpaso0 = false;
  public valpaso0 = false;

  /**Variable que indica que el usuario esta en el paso 1 de registro*/
  public regpaso1 = false;
  public valpaso1 = false;

  /**Variable que indica que el usuario esta en el paso 2 de registro*/
  public regpaso2 = false;
  public valpaso2 = false;

  /**Variable que indica que el usuario esta en el paso 3 de registro*/
  public regpaso3 = false;
  public valpaso3 = false;

  public finalizo = false;

  public cmodulo = null;

  public ctransaccion = null;

  constructor(public localStorageService: LocalStorageService, router: Router, dtoServicios: DtoServicios, public appService: AppService,private domSanitizer: DomSanitizer) {
    super(localStorageService, router, dtoServicios, 'TbanSuscripcion', 'TBANSUSCRIPCION', true, true);
    this.urlterminos=this.domSanitizer.bypassSecurityTrustResourceUrl(environment.terminos);
  }
  urlterminos:any;
  ngOnInit() {
    this.componentehijo = this;
    super.init();
  }

  
  validateInput(event: any) {
    const key = event.key;
    const isValidKey = (key >= '0' && key <= '9') || key === 'Backspace'; // Verifica si la tecla presionada es un número o la tecla de retroceso (Backspace)
    const inputValue = event.target.value;
  
    const isPasteEvent = (event.type === 'paste') || (event.ctrlKey && key === 'v'); // Verifica si el evento es de tipo "pegar" o si se presiona Ctrl + V
  
    if (!isValidKey || (isValidKey && inputValue.length >= 12 && !isPasteEvent)) {
      event.preventDefault(); // Evita la entrada del carácter no válido o si se alcanza la longitud máxima del campo (excepto en eventos de pegar con Ctrl + V)
    }
  }
  

  restrictLowerCase(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
    event.preventDefault();
  }
  

  ngAfterViewInit() {
  }

  crearNuevo() {
    super.crearNuevo();
    this.registro.estatuscusuarioccatalogo = 2301;

    /**Variable que indica que el usuario esta en el paso 0 de registro*/
    this.regpaso0 = false;
    this.valpaso0 = false;

    /**Variable que indica que el usuario esta en el paso 1 de registro*/
    this.regpaso1 = false;
    this.valpaso1 = false;

    /**Variable que indica que el usuario esta en el paso 2 de registro*/
    this.regpaso2 = false;
    this.valpaso2 = false;

    /**Variable que indica que el usuario esta en el paso 3 de registro*/
    this.regpaso3 = false;
    this.valpaso3 = false;
  }

  actualizar() {
    super.actualizar();
  }

  eliminar() {
    // NO APLICA
  }

  cancelar() {
    super.cancelar();
  }

  public selectRegistro(registro: any) {
    super.selectRegistro(registro);
  }

  // Inicia CONSULTA *********************
  consultar() {

  }

  public cancelarRegistroUsuario() {
    this.lregistros = [];
    
    this.encerarMensajes();
    this.registrousuario = false;
    this.regpaso1 = false;
    this.regpaso2 = false;
    this.regpaso3 = false;
    this.finalizo = false;
  }

  ejecutaValidaDatosSocio() {
    this.encerarMensajes();
    this.cmodulo = 23;
    this.ctransaccion = 1;

    this.selectRegistro(this.registro);
    this.registro.estatuscusuariocdetalle = 'VAL';
    this.actualizar();
    this.grabar();
  }

  ejecutaValidaCredenciales() {
    this.encerarMensajes();
    this.cmodulo = 23;
    this.ctransaccion = 7;

    this.selectRegistro(this.registro);
    this.registro.estatuscusuariocdetalle = 'VAL';
    this.actualizar();
    this.grabar();
  }

  ejecutaActualizaDatos() {
    this.encerarMensajes();
    this.cmodulo = 23;
    this.ctransaccion = 8;

    this.selectRegistro(this.registro);
    this.registro.cusuario=this.registro.identificacion;
    this.registro.estatuscusuariocdetalle = 'VAL';
    this.actualizar();
    this.grabar();
  }

  ejecutaValidaClaveOtp() {
    this.encerarMensajes();
    this.cmodulo = 23;
    this.ctransaccion = 2;

    this.selectRegistro(this.registro);
    this.registro.estatuscusuariocdetalle = 'OTP';
    this.actualizar();

    this.rqMantenimiento['claveotp'] = this.mcampos.claveotp;
    this.grabar();
  }

  ejecutaCrearUsuario() {
    this.cmodulo = 23;
    this.ctransaccion = 3;

    // this.registro.cusuario = this.registro.identificacion;

    if (this.registro.password !== this.mcampos.claveconf) {
      this.mostrarMensajeError('LAS CLAVES INGRESADAS NO COINCIDEN');
      return;
    }

    this.selectRegistro(this.registro);
    this.registro.estatuscusuariocdetalle = 'ACT';
    this.actualizar();

    this.rqMantenimiento['claveconf'] = this.mcampos.claveconf;
    this.grabar();
  }

  // Inicia MANTENIMIENTO *********************
  grabar(): void {
    this.rqMantenimiento['CODMODULOORIGEN'] = this.cmodulo;
    this.rqMantenimiento['CODTRANSACCIONORIGEN'] = this.ctransaccion;
    this.rqMantenimiento['cmodulo'] = this.cmodulo;
    this.rqMantenimiento['ctransaccion'] = this.ctransaccion;

    this.lmantenimiento = []; // Encerar Mantenimiento
    this.crearDtoMantenimiento();

    const rqMan = this.getRequestMantenimiento();
    this.dtoServicios.ejecutarServicioBancaLinea(rqMan, this.cmodulo, this.ctransaccion)
      .subscribe(
      (resp:any) => {
        if (resp.cod === 'OK') {
          this.grabo = true;
        }
        this.encerarMensajes();
        this.respuestacore = resp;
        this.componentehijo.postCommit(resp);
        this.dtoServicios.llenarMensaje(resp, true, this.limpiamsgpeticion); // solo presenta errores.
        this.enproceso = false;
      },
      error => {
        this.dtoServicios.manejoError(error);
        this.enproceso = false;
        this.grabo = false;
      });
  }

  public crearDtoMantenimiento() {
    const mantenimiento = super.getMantenimiento(1);
    super.addMantenimientoPorAlias(this.alias, mantenimiento);
  }

  public postCommit(resp: any) {
    super.postCommitEntityBean(resp);

    if (resp.cod === 'OK') {
      if (this.ctransaccion === 7) {
        this.valpaso0 = true;
        this.regpaso0 = false;
        this.regpaso1 = true;
        this.regpaso2 = false;
        this.regpaso3 = false;
        this.mostrarMensajeSuccess('VALIDACIÓN DE CREDENCIALES DEL PARTÍCIPE EXITOSO');
        if (!this.estaVacio(resp.email)) {
          this.registro.email = resp.email;
        }
        if (!this.estaVacio(resp.celular)) {
          this.registro.mdatos.celular = resp.celular;
        }
      } else if (this.ctransaccion === 8) {
        this.valpaso1 = true;
        this.regpaso0 = false;
        this.regpaso1 = false;
        this.regpaso2 = true;
        this.regpaso3 = false;
        this.mostrarMensajeSuccess('ACTUALIZACIÓN DE DATOS DEL PARTÍCIPE EXITOSO');
      } else if (this.ctransaccion === 2) {
        this.valpaso2 = true;
        this.regpaso0 = false;
        this.regpaso1 = false;
        this.regpaso2 = false;
        this.regpaso3 = true;
        this.mostrarMensajeSuccess('VALIDACIÓN OTP EXITOSA');
      } else if (this.ctransaccion === 3) {
        this.valpaso3 = true;
        this.regpaso3 = false;
        this.finalizo = true;
        this.mostrarMensajeSuccess('REGISTRO DE USUARIO EXITOSO');
      }
    }
  }

  public hasTwoDigits(password: string): boolean {
    const digitRegex = /\d/g; // Expresión regular para buscar dígitos
    const matches = password.match(digitRegex); // Buscar coincidencias de dígitos en la contraseña
    
    return matches && matches.length >= 2; // Verificar si se encontraron al menos 2 dígitos
  }
  

}
