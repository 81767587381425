import {NgModule} from '@angular/core';
import {SharedModule} from '../../../../util/shared/shared.module';
import {LoginRoutingModule} from './login.routing';
import {SubscripcionModule} from '../subscripcion/subscripcion.module';

import { LoginComponent } from './componentes/login.component';
import { ReloadComponent } from './componentes/reload.component';

@NgModule({
  imports: [SharedModule, LoginRoutingModule, SubscripcionModule],
  declarations: [LoginComponent, ReloadComponent],
  exports: [LoginComponent, ReloadComponent]
})
export class LoginModule {}
