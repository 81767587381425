import { SoloFechaPipe } from './../pipe/solofecha.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
  InputTextModule, MenuModule, ButtonModule, MessagesModule, InputMaskModule, DataTableModule, DialogModule, FieldsetModule,
  PanelModule, DropdownModule, CheckboxModule, RadioButtonModule, TabViewModule, AccordionModule, CalendarModule, FileUploadModule
} from 'primeng/primeng';


import { MayusculasDirective } from '../directivas/mayusculas.directive';
import { EnteroDirective } from '../directivas/entero.directive';
import { FocoDirective } from '../directivas/foco.directive';
import { NumeroDirective } from '../directivas/numero/numero.directive';
import { NumeroPositivoDirective } from '../directivas/numero/numeroPositivo.directive';
import { NumeroDecimal7Directive } from '../directivas/numero/numeroDecimal7.directive';
import { EnteroGuionDirective } from '../directivas/enteroGuion.directive';
import { LetrasDirective } from '../directivas/letras.directive';
import { FechaEnteroPipe } from '../pipe/fechaentero.pipe';
import { EnteroFechaFormatoPipe } from '../pipe/fechaenteroformato';





@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [MayusculasDirective, EnteroDirective, FocoDirective, NumeroDirective,EnteroFechaFormatoPipe, FechaEnteroPipe, LetrasDirective, NumeroPositivoDirective, NumeroDecimal7Directive, EnteroGuionDirective,SoloFechaPipe
    ],
  exports: [MayusculasDirective, EnteroDirective, FocoDirective, NumeroDirective, FechaEnteroPipe,EnteroFechaFormatoPipe, LetrasDirective, NumeroPositivoDirective, NumeroDecimal7Directive, EnteroGuionDirective,
    CommonModule, FormsModule, InputTextModule, MenuModule, ButtonModule, MessagesModule, InputMaskModule, DataTableModule, DialogModule,
    FieldsetModule, PanelModule, DropdownModule, CheckboxModule, RadioButtonModule, TabViewModule, AccordionModule, CalendarModule, FileUploadModule,SoloFechaPipe]
})
export class BasicModule { }
