import { Component, Inject, forwardRef } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-footer',
    template: `
        <div class="card" style="height:45px;">
            <div class="footer">
                    <span class="footer-text-left">Core SIAC</span>
                    <span class="footer-text-right"><span>Asistecooper S.A.<br/>All Rights Reserved</span></span>
            </div>
        </div>
    `
})
export class AppFooter {

}