import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/util/seguridad/sesion/localStorageService';



@Component({
  selector: 'app-inicio',
  template: ``
})
export class InicioComponent implements OnInit {

  constructor(public localStorageService: LocalStorageService,public router: Router) {
  }


  ngOnInit() {
    if (this.localStorageService.getItem('path') !== undefined && this.localStorageService.getItem('path') !== null) {
      this.router.navigate([this.localStorageService.getItem('path')], { skipLocationChange: true });
    }
  }
}
