import { Component, Inject, forwardRef, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { LoginComponent } from '../util/seguridad/componentes/login/componentes/login.component';
import { AppService } from '../util/servicios/app.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-topbar',
  template: `
        <div class="topbar clearfix">
            <div class="topbar-left">
                <div class="logo"></div>
            </div>
            <div class="topbar-right">
                <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)" >
                    <i></i>
                </a>
                <span style='color:white;font-size:16px;font-family: Roboto,"Helvetica Neue",sans-serif;-webkit-font-smoothing: antialiased;'>{{ nombre }}</span>
                <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
                    <i class="material-icons">menu</i>
                </a>

                <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
                    <li #settings [ngClass]="{'active-top-menu':app.activeTopbarItem === settings}">
                        <a href="#" (click)="app.onTopbarItemClick($event,settings)">
                            <i class="topbar-icon material-icons">settings</i>
                            <span class="topbar-item-name">Settings</span>
                        </a>
                        <ul class="ultima-menu animated fadeInDown">
                            <li role="menuitem">
                                <a href="#"  (click)="appService.cambioPassword($event)">
                                    <i class="material-icons">palette</i>
                                    <span>Cambiar password</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="appService.salir($event)">
                                    <i class="material-icons">lock</i>
                                    <span>Salir</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li #info class="" [ngClass]="{'active-top-menu':app.activeTopbarItem === info}" (click)="app.onTopbarItemClick($event,info)">
                        <a href="#" (click)="appService.muestraUserInfo = !appService.muestraUserInfo" style="margin: 10px auto;"
                                    *ngIf="!(!appService.login || appService.validarotp || appService.cambiopassword)">
                          <label class="app-info-fecha" style="cursor: pointer;">{{integerToFormatoFecha(app.dtoServicios.mradicacion.ftrabajo)}}</label>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    `
})
export class AppTopBar {
  @ViewChild(LoginComponent)
  logincomponent: LoginComponent;

  constructor( @Inject(forwardRef(() => AppComponent)) public app: AppComponent, public appService: AppService) { }
  nombre:any=environment.fondo;
  public integerToFormatoFecha(valor: number): string {
    // ejemplo yyyyMMdd 20170131    31 de enero del 2017
    const anio = valor.toString().substring(0, 4);
    const mes = valor.toString().substring(4, 6);
    const dia = valor.toString().substring(6, 8);
    const fecha = anio + '-' + mes + '-' + dia;
    return fecha;
  }

}
