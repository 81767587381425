import {Directive, Output, EventEmitter} from '@angular/core';
import { NgModule }      from '@angular/core';

@Directive({
    selector: '[ngModel][mayusculas]',
    host: {
        "(input)": 'onInputChange($event)'
    }
})
export class MayusculasDirective {
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter()
    value: string;

    onInputChange($event: any) {
        this.value = $event.target.value.toUpperCase()
        this.ngModelChange.emit(this.value)
    }
}