import { Injectable } from '@angular/core';
import { StorageService } from '../../servicios/storage.service';
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private storageService: StorageService) { }
  setItem(key: string, value: any) {
    this.storageService.secureStorage.setItem(key, value);
  }
  getItem(key: string) {
    return this.storageService.secureStorage.getItem(key);
  }
  clearToken() {
    return this.storageService.secureStorage.clear();
  }
  clear() {
    return this.storageService.secureStorage.clear();
  }
}
