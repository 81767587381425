import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-inicio',
  template: `<h2>Bienvenido</h2>`
})
export class InicioComponent  {


}
