import { Component, NgModule, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'app/util/seguridad/sesion/localStorageService';

@Component({
  selector: 'app-reload',
  template: `
          
    `
})
export class ReloadComponent implements OnInit {

  constructor(public localStorageService:LocalStorageService) { }

  ngOnInit() {
      this.localStorageService.clear();      
      location.reload();
  }

}
