import { Injectable } from '@angular/core';
import 'rxjs/add/operator/first';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { Mantenimiento } from '../../util/dto/dto.component';
import { AlertService } from '../../util/servicios/alert.service';
import { Routes, Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/primeng';
import { LocalStorageService } from '../seguridad/sesion/localStorageService';

@Injectable()
export class Radicacion {
  public ccompania: number;
  public csucursal: number;
  public cagencia: number;
  public cusuario: string;
  public ccanal: string;
  public ncompania: string;
  public nsucursal: string;
  public nagencia: string;
  public timeoutminutos: number;
  public timeoutsegundos: number;
  public fcontable: number;
  public cpersona: number;
  public np: string;
  public ftrabajo: string;
  public nambiente;

  constructor(ccompania: number, csucursal: number, cagencia: number, cusuario: string, ccanal: string, ncompania: string) {
    this.ccompania = ccompania;
    this.csucursal = csucursal;
    this.cagencia = cagencia;
    this.cusuario = cusuario;
    this.ccanal = ccanal;
    this.ncompania = ncompania;
  }
}

@Injectable()
export class DtoServicios {
  //config: any = { 'host': 'http://localhost', 'port': '8082', 'context': '/atlas-rest/servicioRest' };
  //config: any = { 'host': 'http://192.168.50.99', 'port': '8081', 'context': '/WebApiCore/atlas-rest/servicioRest' };
  config: any = { 'host': 'https://fondosconsultasmdmq.com', 'port': '11443', 'context': '/WebApiCore/atlas-rest/servicioRest'};


  public urlConsultar = '';
  public urlMantener = '';
  public urlLogin = '';

  /**Objeto que gurada datos temporales utiiados en la transaccion. */
  public mradicacion: Radicacion;

  public ipreal = ' ';

  public mostrarDialogoLoading = false;
  public mostrarDialogoLoadingTemp = false;

  public mensajeDialogTemp = ' ';

  /**Objeto que contine mensajes aplicativos. */
  msgs: Message[] = [];

  /**Variables de timeout. */
  public minutos: number;
  public segundos: number;
  public intervalID: any;
  public logeado = false;



  constructor(public localStorageService: LocalStorageService, public http: HttpClient, public router: Router, public alertService: AlertService) {
    this.urlLogin = this.urlLogin + this.config.host + ':' + this.config.port + '' + this.config.context + '/login';
    //this.timeout();
  }

  public timeout() {
    this.resetTimer();
    this.intervalID = setInterval(() => this.tick(), 1000);
  }

  public tick(): void {
    if (--this.segundos < 0) {
      this.segundos = 59;
      if (--this.minutos < 0) {
        this.logout()
      }
    }
  }

  public resetTimer(): void {
    clearInterval(this.intervalID);
    if (this.logeado) {
      this.minutos = this.mradicacion.timeoutminutos;
      this.segundos = this.mradicacion.timeoutsegundos;
    }
    else {
      this.minutos = 14;
      this.segundos = 59;
    }
  }

  public setUrlMantener(appName: any, metodo: any) {
    this.urlMantener = '';
    if (this.estaVacio(appName)  && this.estaVacio(metodo)) {
      this.urlMantener = this.urlMantener + this.config.host + ':' + this.config.port + '' + this.config.context + '/mantener';
    }
    if (this.estaVacio(appName) && !this.estaVacio(metodo)) {
      this.urlMantener = this.urlMantener + this.config.host + ':' + this.config.port + '' + this.config.context + '/' + metodo;
    }
    if (!this.estaVacio(appName)  && this.estaVacio(metodo)) {
      this.urlMantener = this.urlMantener + this.config.host + ':' + this.config.port + '/' + appName + this.config.context + '/mantener';
    }
    if (!this.estaVacio(appName) && !this.estaVacio(metodo)) {
      this.urlMantener = this.urlMantener + this.config.host + ':' + this.config.port + '/' + appName + this.config.context + '/' + metodo;
    }
  }

  public setUrlConsultar(appName: any, metodo: any) {
    this.urlConsultar = '';
    if (this.estaVacio(appName) && this.estaVacio(metodo)) {
      this.urlConsultar = this.urlConsultar + this.config.host + ':' + this.config.port + '' + this.config.context + '/consultar';
    }
    if (this.estaVacio(appName) && !this.estaVacio(metodo)) {
      this.urlConsultar = this.urlConsultar + this.config.host + ':' + this.config.port + '' + this.config.context + '/' + metodo;
    }
    if (!this.estaVacio(appName) && this.estaVacio(metodo)) {
      this.urlConsultar = this.urlConsultar + this.config.host + ':' + this.config.port + '/' + appName + this.config.context + '/consultar';
    }
    if (!this.estaVacio(appName) && !this.estaVacio(metodo)) {
      this.urlConsultar = this.urlConsultar + this.config.host + ':' + this.config.port + '/' + appName + this.config.context + '/' + metodo;
    }

  }

  public dateParser(key, value) {
    const reISOFull = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})?$/;

    if (typeof value === 'string' && value.indexOf(':00:00') > 0) {
      //const a = reISOFull.exec(value);
      if (reISOFull.exec(value) || reISO.exec(value)) {
        const anio = value.substr(0, 4);
        const mes = value.substr(5, 2);
        const dia = value.substr(8, 2);
        return new Date(anio + '-' + mes + '-' + dia + ' 00:00:00');
      }
    }
    return value;
  };

  public dateString(key, value) {
    const reISOFull = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})?$/;
    return value;
  };

  public obtenerIpLocal() {
    this.consultarIpLocal();
  }

  public consultarIpLocal() {
    const win: any = window;
    win.RTCPeerConnection = win.RTCPeerConnection || win.mozRTCPeerConnection || win.webkitRTCPeerConnection;
    if (win.RTCPeerConnection === undefined) {
      return;
    }
    const pc: any = new RTCPeerConnection({ iceServers: [] }), noop = function () { };
    try {
      pc.createDataChannel('');
      pc.createOffer(pc.setLocalDescription.bind(pc), noop);    // create offer and set local description
      pc.onicecandidate = this.registraIp;
    } catch (e) { }
  }

  registraIp = (ice) => {
    if (!ice || !ice.candidate || !ice.candidate.candidate) { return };
    const resp = /([0-9]{1,3}(\.[0-9]{1,3}){3})/.exec(ice.candidate.candidate);
    if (resp != null && resp.length > 0) {
      this.ipreal = resp[0];
    }
  };
  ejecutarServicioBancaLinea(prq: any, cmodulo, ctransaccion) {
    const cia = '1';
    let c: string = cia + '^1^1^' + 'BLINEA' + '^1^ES^' + 'BAN' + '^' + this.ipreal + '^' + cmodulo + '^' + ctransaccion;
    const rqLogin = new Object();
    prq['c'] = c;
    prq['vl'] = '0';

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.setUrlMantener(null, null);
    this.mostrarDialogoLoading = true;

    return this.http.post(this.urlMantener, prq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(map(resp => {
        const r = resp;
        // this.localStorageService.setItem('jwt', resp.headers['_headers'].get('x-auth-token'));
        this.mostrarDialogoLoading = false;
        return r;
      }));
  }
  /**Llamar al servicio rest de consulta */
  public ejecutarConsultaRest(rqConsulta: Object, appName: any = null, metodo: any = null) {
    rqConsulta['c'] = this.getCabecera(rqConsulta);
    const rq = JSON.stringify(rqConsulta, this.dateString);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('x-auth-token', this.localStorageService.getItem('jwt'));
    this.setUrlConsultar(appName, metodo);
    this.mostrarDialogoLoading = true;


    return this.http.post(this.urlConsultar, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(map((resp: any) => {
        const r = resp;
        this.mostrarDialogoLoading = false;
        this.timeout();
        return r;
      }));
  }

  /**Manejo de respuesta de la base de datos cuando existe un error */
  public handleError(error: any) {
    return Promise.reject(error.message || error);
  }
  public estaVacio(obj: any): boolean {
    if (obj === undefined || obj === null || obj === '' || (typeof obj === 'object' && !(obj instanceof Date) && Object.keys(obj).length === 0) || obj.length <= 0) {
      return true;
    }
    return false;
  }

  /**Llamar al servicio rest de consulta */
  ejecutarRestMantenimiento(rqMan: Object, appName: any = null, metodo: any = null) {
    rqMan['c'] = this.getCabecera(rqMan);
    const rq = JSON.stringify(rqMan, this.dateString);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('x-auth-token', this.localStorageService.getItem('jwt'));
    this.setUrlMantener(appName, metodo);
    this.mostrarDialogoLoading = true;

    return this.http.post(this.urlMantener, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(map((resp: any) => {
        const r = resp;
        this.mostrarDialogoLoading = false;
        this.timeout();
        return r;
      }));
  }

  /**Llamar al servicio rest de consulta */
  ejecutarLogin(usuario: string, password: string, canal: string, transaccion: string) {
    const rqLogin = this.getRequestLogin(usuario, password, canal, transaccion);
    const rq = JSON.stringify(rqLogin);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.mostrarDialogoLoading = true;

    return this.http.post(this.urlLogin, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .pipe(map(resp => {
        const r = resp;
        this.mostrarDialogoLoading = false;
        this.timeout();
        return r;
      }));
  }

  /**Llamar al servicio rest de consulta */
  ejecutarRecuperarContrasenia(identificacion: string, canal: string, transaccion: string) {
    const rqLogin = this.getRequestLogin(identificacion, identificacion, canal, transaccion);
    const rq = JSON.stringify(rqLogin);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.setUrlMantener('', 'mantener');
    this.mostrarDialogoLoading = true;
    return this.http.post(this.urlMantener, rq, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), withCredentials: true })
      .pipe(map(resp => {
        const r = resp;
        this.mostrarDialogoLoading = false;
        this.timeout();
        return r;
      }));
  }

  public getRequestLogin(usuario: string, password: string, canal: string, transaccion: string) {
    const cia = '1';
  
    let c: string = cia + '^1^1^' + usuario + '^1^ES^' + canal + '^' + this.ipreal + '^2^' + transaccion;
    const rqLogin = new Object();
    rqLogin['c'] = c;
    rqLogin['p'] = password;
    rqLogin['vl'] = '0'; // valida login
    return rqLogin;
  }

  public getCabecera(request: Object): string {
    this.obtenerIpLocal();
    let r: string = this.localStorageService.getItem('c');
    if (request.hasOwnProperty('cmodulo') && request['cmodulo'] !== undefined) {
      r = r + '^' + this.ipreal + '^' + request['cmodulo'] + '^' + request['ctransaccion'];
    } else {
      r = r + '^' + this.ipreal + '^' + this.localStorageService.getItem('m') + '^' + this.localStorageService.getItem('t');
    }
    return r;
  }

  logout(msg = '') {
    const rqLogin = new Object();
    rqLogin['logoutuser'] = true;
    rqLogin['valida-usuario-login'] = false;
    rqLogin['cmodulo'] = '2';
    rqLogin['ctransaccion'] = '1001';

    const rq = JSON.stringify(rqLogin);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.ejecutarRestMantenimiento(rqLogin, '', 'logout').subscribe(
      resp => {
        this.llenarMensaje(resp, true); // solo presenta errores.
        // encerar token eliminar datos de radicacion del usuario.
        this.encerarCredencialesLogin();
        location.reload();
      },
      error => {
        this.manejoError(error);
      }
    );
  }

  public encerarCredencialesLogin() {
    this.localStorageService.clear();
  }

  // Metodo que inicializa los datos cuando un usuario se autentica exitosamente
  actualizarRadicacion(mradicacion: any): void {
    this.mradicacion = new Radicacion(mradicacion.cc, mradicacion.cs, mradicacion.cag, mradicacion.cu, mradicacion.cca, mradicacion.nc);
    this.mradicacion.nsucursal = mradicacion.ns;
    this.mradicacion.nagencia = mradicacion.age;
    this.mradicacion.timeoutminutos = mradicacion.timeoutminutos;
    this.mradicacion.timeoutsegundos = mradicacion.timeoutsegundos;
    this.mradicacion.fcontable = mradicacion.fcontable;
    this.mradicacion.cpersona = mradicacion.cp;
    this.mradicacion.np = mradicacion.np;
    this.mradicacion.ftrabajo = mradicacion.ftrabajo;
    this.mradicacion.nambiente = mradicacion.ambiente;
    this.logeado = true;
  }


  // OTROS ********************************************************
  public llenarMensaje(resp: any, muestaexito: boolean, limpiamsg = true) {
    if (limpiamsg) {
      this.msgs = [];
    }
    if (resp.cod === 'OK') {
      if (muestaexito) {
        this.msgs.push({ severity: 'success', summary: 'TRANSACCIÓN FINALIZADA CORRECTAMENTE', detail: '' });
      }
      
    } else if (resp.cod === 'F-000' || resp.cod === 'BGEN-021' || resp.cod === 'SEG-031') {
      // Sesion expirada, realizar logout      
      let msg = '';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      this.msgs.push({ severity: 'error', summary: msg, detail: '' });
      this.logout(msg);
      this.alertService.mostrarAlerta(msg, true);
    } else if (resp.cod === '000') {
      let msg = '';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      this.msgs.push({ severity: 'warn', summary: msg, detail: '' });
    } else {
      let msg = '';
      msg = resp.cod !== undefined ? msg = msg + resp.cod.startsWith('javax') ? '' : resp.cod + ' ' : msg + ' ';
      msg = resp.msgusu !== undefined ? msg = msg + resp.msgusu : msg + '';
      this.msgs.push({ severity: 'error', summary: msg, detail: '' });
    }
    this.alertService.mostrarMensaje(this.msgs);
  }

  /**Fija mensaje de respuesta resultado de ejecucion de una peticion al core. */
  public manejoError(resp: any) {
    let mensaje = 'ERROR DE CONEXIÓN CON EL SERVIDOR';
    if (resp.message !== undefined && resp.message !== null) {
      mensaje = resp.message;
    }
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: mensaje, detail: '' });
    this.alertService.mostrarMensaje(this.msgs);
  }

  /**Fija mensaje de respuesta resultado de ejecucion de una peticion al core. */
  public mostrarMensaje(msgs: Message[], persistirmsg = false, acumular = false) {
    if (!acumular) {
      this.msgs = msgs;
    } else {
      this.msgs = this.msgs.concat(msgs);
    }
    this.alertService.mostrarMensaje(this.msgs, persistirmsg);
  }

}
